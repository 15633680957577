import React from 'react'
import styled from 'styled-components'

// Elements
import BlockDefault from 'components/elements/Blocks/BlockDefault'
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import NavLink from 'components/shared/NavLink'

const Section = styled.section`
  @media (min-width: 992px) {
    margin-top: -6rem;
  }
`

const StyledBlockDefault = styled(BlockDefault)`
  position: relative;
  z-index: 3;
  @media (max-width: 1399px) {
    & img {
      width: 50px;
      height: 50px;
    }
  }
`

const StyledNavLink = styled(NavLink)`
  @media (max-width: 375px) {
    height: 100%;
    width: 100%;
    font-size: ${({ theme }) => theme.font.size.small};
  }
`

interface TextWithBlocksProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithBlocks: React.FC<TextWithBlocksProps> = ({ fields }) => (
  <Section className="mb-5">
    <div className="container py-5">
      <div className="row justify-content-center">
        <div className="col-lg-12">
          <div className="row">
            <div className="col-lg-5 d-flex align-items-center">
              <ParseContent
                content={fields.description}
                className="pe-1 pt-lg-4"
              />
            </div>
            <div className="col-lg-7 mt-n5">
              <div className="row mt-5 mt-lg-0">
                {fields.blocks?.map((block, index: number) => (
                  <div
                    className="d-flex flex-column justify-content-center align-items-center col-sm-4 col-md-3 col-lg-4 col-xl-3 col-6 mb-3"
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                  >
                    <StyledNavLink to={block?.link?.url || '/'}>
                      <StyledBlockDefault>
                        <Plaatjie
                          image={block?.icon}
                          alt={block?.link?.title || 'Icoon'}
                          className="mb-3 ms-sm-0"
                        />
                        <span>{block?.link?.title}</span>
                      </StyledBlockDefault>
                    </StyledNavLink>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Section>
)

export default TextWithBlocks
