import React, { useState } from 'react'
import styled from 'styled-components'
import { motion } from 'framer-motion'
import { useMedia } from 'use-media'

// Elements
import ParseContent from 'components/shared/ParseContent'
import NavLink from 'components/shared/NavLink'

// Images
import ArrowRight from 'img/arrowright.inline.svg'

const LinkWrapper = styled.div`
  border: 3px solid ${({ theme }) => theme.color.secondary};
  border-right: unset;
  border-top-left-radius: 90px;
  border-bottom-left-radius: 90px;

  & a {
    font-family: ${({ theme }) => theme.font.family.secondary};
    @media (min-width: 576px) {
      font-size: ${({ theme }) => theme.font.size.huge};
    }
    @media (max-width: 575px) {
      font-size: ${({ theme }) => theme.font.size.medium};
    }
  }

  & svg {
    @media (min-width: 1400px) {
      width: 60px;
      height: 60px;
    }
  }

  &:after {
    content: '';
    position: absolute;
    left: 100%;
    top: 0px;
    bottom: 0px;
    width: 2000px;
    border-top: 3px solid ${({ theme }) => theme.color.secondary};
    border-bottom: 3px solid ${({ theme }) => theme.color.secondary};
    background: transparent;
    margin-top: -0.2rem;
    margin-bottom: -0.18rem;
  }
`

const LinkContainer = styled.div`
  @media (min-width: 1400px) {
    width: 75%;
  }
  @media (max-width: 1399px) {
    width: 100%;
  }

  & a {
    color: ${({ theme }) => theme.color.primary} !important;
  }
`

interface TextWithLinksCurvedProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Text
}

const TextWithLinksCurved: React.FC<TextWithLinksCurvedProps> = ({
  fields,
}) => {
  const isSmallDevice = useMedia({ maxWidth: 575 })
  const [isHover, setHover] = useState<number | null>(null)

  return (
    <section className="py-5 mb-5">
      <div className="container position-relative">
        <div className="row justify-content-center">
          <div className="col-lg-12">
            <div className="row">
              <div className={`col-xl-${fields.columnwidth}`}>
                <ParseContent
                  content={fields.description}
                  className="pe-sm-5"
                />
              </div>
              <div
                className={`col-xl-${
                  12 - (fields.columnwidth || 0)
                } mt-5 mt-xl-0`}
              >
                {fields.links?.map((link, index: number) => {
                  const hovered: boolean = index === isHover

                  return (
                    <LinkWrapper
                      className="position-relative d-flex justify-content-xl-center mb-5 px-5 py-4"
                      // eslint-disable-next-line react/no-array-index-key
                      key={index}
                    >
                      <LinkContainer
                        className="d-flex justify-content-between justify-content-xl-center align-items-center"
                        onMouseEnter={() => setHover(index)}
                        onMouseLeave={() => setHover(null)}
                      >
                        <NavLink
                          className="d-flex w-100 align-items-center"
                          to={link?.link?.url || '/'}
                        >
                          <div className="w-50">{link?.link?.title}</div>
                          <div className="d-flex justify-content-end w-50">
                            <motion.div
                              className="ms-sm-5"
                              animate={
                                hovered
                                  ? { x: isSmallDevice ? 0 : -89 }
                                  : { x: isSmallDevice ? 0 : -99 }
                              }
                              transition={{ type: 'spring', stiffness: 100 }}
                            >
                              <ArrowRight />
                            </motion.div>
                          </div>
                        </NavLink>
                      </LinkContainer>
                    </LinkWrapper>
                  )
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default TextWithLinksCurved
